<template>
  <section class="bg_color">
    <h2>モーター別獲得モグーランキング</h2>
    <section class="inner">
      <div class="ribon_outline">
        <div class="ttl_ribon rank">
          <p class="ribon_inner"><Today /></p>
        </div>
      </div>
      <h3 class="ttl_main ttl_main--mb"><LastRace /></h3>

      <!--moter-->
      <div v-if="info">
        <div class="box_lst slide rank">
          <!-- <div class="box_lnk MB">
            <a href="" class="btn_prev nolink">前の10件</a>
            <p class="count">1/10ページ</p>
            <a href="" class="btn_next">次の10件</a>
          </div> -->
          <div class="box_rank_moter">
            <p class="scroll visible_sp">SCROLL</p>
            <div class="inner">
              <table>
                <tbody>
                  <tr>
                    <th>順 位</th>
                    <th>機 番</th>
                    <th>獲得モグー</th>
                    <th>優勝回数</th>
                    <th>直近の使用者</th>
                  </tr>

                  <tr v-for="data in info" :key="data.mno">
                    <td class="rank first">
                      {{ data.rank }}<span class="small">位</span>
                    </td>
                    <td>{{ Number(data.mno) }}</td>
                    <td>
                      <span class="mog">{{
                        addComma(Number(data.point))
                      }}</span>
                    </td>
                    <td>{{ data.win_count }}<span class="small">回</span></td>
                    <td class="user">
                      <p>
                        {{ data.current_racer
                        }}<span class="small"
                          >（{{ data.current_racer_kyu }}／{{
                            data.race_grade
                          }}
                          {{ data.fdate }}～{{ data.tdate }}）</span
                        >
                      </p>
                      <router-link
                        :to="{ name: 'MotorDtl', params: { id: data.mno } }"
                        class="btn_dtl"
                        >モーター履歴を見る</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- <div class="box_lnk MB">
          <a href="" class="btn_prev nolink">前の10件</a>
          <p class="count">1/10ページ</p>
          <a href="" class="btn_next">次の10件</a>
        </div> -->
      </div>
    </section>
  </section>
</template>

<script>
import Mixin from "@/mixins/mixin";
import LastRace from "@/components/LastRaceInfo.vue";
import Today from "@/components/Today.vue";
export default {
  name: "RankingMotor",
  components: {
    LastRace,
    Today,
  },
  mixins: [Mixin],
  beforeMount: function () {
    this.$store.dispatch("rankingMotorInfo");
  },
  mounted: function () {
    this.$setInterval(() => {
      this.$store.dispatch("rankingMotorInfo");
    }, 15000);
  },
  computed: {
    info() {
      return this.$store.getters.getRankingMotorInfo.mog_ranking;
    },
  },
  beforeUnmount: function () {
    this.$clearAllIntervals();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking.css" scoped></style>
